import { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import '../sass/base/_global.scss'
import '../sass/components/caas-grid/_caas-grid.scss'
import '../sass/components/caas-grid/_caas-claim-grid.scss'
import theme from '../themes/muiTheme'
import tenantConfig from '../tenantConfig.json'
import { CaasClaimsFilter } from '../models/CaasClaimsFilter'
import { AuthContext } from '../auth/AuthProvider'
import { formatDate } from '../services/StringFormater'
import { RiFilterLine } from 'react-icons/ri'
import CaasCustomDataGridMenu from './CaasCustomDataGridMenu'
import CaasCustomFilterPanel from './CaasCustomFilterPanel'
import { CaasApi } from '../api/CaasApi'
import { Dayjs } from 'dayjs'
import ReportIcon from '@mui/icons-material/Report'
import WarningIcon from '@mui/icons-material/Warning'
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto'
import { DateRange } from '@mui/x-date-pickers-pro'
import {
  DataGridPremium,
  GridColDef,
  GridColTypeDef,
  GridFilterModel,
  GridSortModel,
  GridRenderCellParams,
  getGridStringOperators,
  getGridDateOperators,
  GRID_STRING_COL_DEF,
  GridPaginationModel,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import MuiPagination from '@mui/material/Pagination'
import { TablePaginationProps } from '@mui/material/TablePagination'
import { Button, Paper, lighten } from '@mui/material'
import { GetCaasClaimsResponse } from '../models/api/GetCaasClaimsResponse'
import ErrorSnackbar from './ErrorSnackbar'
import { ICaasClaim } from '../models/api/ICaasClaim'
import {
  gridFilteredTopLevelRowCountSelector,
  gridPageSizeSelector,
  useGridRootProps,
} from '@mui/x-data-grid'
import {
  addDataQaAttributesToCaasHomepage,
  addDataQaAttributesToPagination,
} from './Utils'
import { IoMdRefresh } from 'react-icons/io'
import CaasCustomColumnsPanel from './CaasCustomColumnsPanel'
import CaasCustomSortIcon from './CaasCustomSortIcon'
import { ITenant } from '../models/api/ITenant'
import SelectTenantDropdown from './SelectTenantDropdown'

function Pagination({
  page,
  onPageChange,
  className,
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>) {
  const getPageCount = (rowCount: number, pageSize: number): number => {
    if (pageSize > 0 && rowCount > 0) {
      return Math.ceil(rowCount / pageSize)
    }

    return 0
  }

  const apiRef = useGridApiContext()
  const rootProps = useGridRootProps()
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector)
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  )

  const pageCount = getPageCount(
    rootProps.rowCount ?? visibleTopLevelRowCount,
    pageSize
  )

  return (
    <MuiPagination
      classes={{ root: 'caas-pagination' }}
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage - 1)
      }}
      showFirstButton
      showLastButton
    />
  )
}

function CustomPagination(props: any) {
  return <GridPagination ActionsComponent={Pagination} {...props} />
}

export function CaasClaimsGrid() {
  const navigate = useNavigate()
  const tenantId = localStorage.getItem('caas_tenant')
  if (tenantId === null) {
    navigate('/caas/tenants')
  }
  const [currentQueryParameters, setSearchParams] = useSearchParams()
  const authProvider = useContext(AuthContext)
  const [tenantName, setUserTenantName] = useState('')
  const [data, setData] = useState<ICaasClaim[]>([])
  const [tenants, setTenants] = useState<ITenant>({})
  const [cols, setCols] = useState<GridColDef[]>([])
  const [rowCount, setRowCount] = useState<number>(0)
  const [rowCountState, setRowCountState] = useState<number>(rowCount)
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(endDate.getDate() - 30))
  )
  const [isLoading, setIsLoading] = useState(true)
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const [hasData, setHasData] = useState(false)
  const [activeFiltersCount, setActiveFiltersCount] = useState(0)

  const [sortModel, setSortModel] = useState<GridSortModel>([])

  const [filterDates, setFilterDates] = useState<DateRange<Dayjs>>([null, null])
  const [filterClaimNumber, setFilterClaimNumber] = useState<string>('')
  const [filterGroupName, setFilterGroupName] = useState<string>('')
  const [filterRenderingProvider, setFilterRenderingProvider] =
    useState<string>('')
  const [filterIsAssigned, setFilterIsAssigned] = useState<boolean>(true)
  const [filterIsAdjudicated, setFilterIsAdjudicated] = useState<boolean>(false)
  const [filterIsAppeal, setFilterIsAppeal] = useState<boolean | undefined>(
    undefined
  )
  const datesMap = new Map<string, number>([
    ['30 Days', 30],
    ['60 Days', 60],
    ['120 Days', 120],
    ['Past Year', 366],
  ])
  const [datesDefault] = useState(Array.from(datesMap.keys())[0])
  const createFilter = (user: string, tenantId: string) => ({
    tenantId: tenantId,
    submitDateTo: endDate.toJSON(),
    submitDateFrom: new Date(
      new Date().setDate(startDate.getDate() - 364)
    ).toJSON(),
    pageNumber: 1,
    pageSize: pageSize,
    claimNumber: '%20',
    renderingProvider: '%20',
    groupName: '%20',
    sortOrder: 'ASC',
    sortField: 'SubmitDate',
    userName: user,
    isAssigned: true,
    isAdjudicated: false,
    isAppeal: undefined,
  })

  const defaultFilter = createFilter(
    authProvider.getUserDetails(authProvider.cognitoAuth).username,
    tenantId!
  )
  const defaultFilterWithEmptyUser = createFilter('', tenantId!)

  const [isCallWaiting, setIsCallWaiting] = useState(false)

  const [currentFilter, setCurrentFilter] =
    useState<CaasClaimsFilter>(defaultFilter)

  const updateClaimsFilter = (filterModel: GridFilterModel) => {
    let updateValues = defaultFilterWithEmptyUser

    filterModel.items.forEach((item) => {
      item.field === 'claimNumber'
        ? (updateValues.claimNumber = item.value)
        : ''
      item.field === 'renderingProvider'
        ? (updateValues.renderingProvider = item.value)
        : ''
      item.field === 'groupName' ? (updateValues.groupName = item.value) : ''
      item.field === 'isAssigned' ? (updateValues.isAssigned = item.value) : ''
      item.field === 'isAdjudicated'
        ? (updateValues.isAdjudicated = item.value)
        : ''
      item.field === 'isAppeal' ? (updateValues.isAppeal = item.value) : ''
      if (item.field === 'submitDate') {
        updateValues.submitDateFrom = item.value[0]
        updateValues.submitDateTo = item.value[1]
      }
    })

    setCurrentFilter((prev) => ({
      ...prev,
      ...updateValues,
    }))

    setPaginationModel({
      page: 0,
      pageSize: 10,
    })
  }

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  const getClaims = async (filter: CaasClaimsFilter) => {
    setData([])
    setIsLoading(true)

    let cr: GetCaasClaimsResponse = await CaasApi.getClaims(filter)

    if (
      !cr.apiRequestStatusNumber ||
      String(cr.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(cr)
      setErrorSnackbarProps({
        errorMessage: cr.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${cr.apiRequestStatusNumber ?? 'unknown'}`,
      })
      setIsLoading(false)
      return
    }

    if (cr.claimCount === 0) {
      setHasData(true)
    }

    setIsLoading(false)
    setData(cr.claimSearchResults)
    setUserTenantName(cr.tenantName)
    setRowCount(cr.claimCount)
    setCols(buildColumns(cr.tenantAcronym))
    addDataQaAttributes()
  }

  const displayAllData = () => {
    setHasData(false)
    setCurrentFilter(defaultFilterWithEmptyUser)
    getClaims(defaultFilterWithEmptyUser)
    setActiveFiltersCount(0)
  }

  const sx = {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700',
      color: `${theme.palette.primary.main}`,
    },
    '& .MuiDataGrid-cell': {
      fontWeight: '700',
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-toolbarContainer': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '& button': {
        color: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
    },
    '& .MuiTablePagination-caption[id]': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-input': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-selectLabel': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
    '& .MuiTablePagination-displayedRows': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
    '& .caas-datagrid-amber-row': {
      backgroundColor: '#FFF3CD',
      '&:hover': {
        backgroundColor: lighten('#FFF3CD', 0.7),
      },
      '&.Mui-selected': {
        backgroundColor: lighten('#FFF3CD', 0.5),
        '&:hover': {
          backgroundColor: lighten('#FFF3CD', 0.4),
        },
      },
    },
    '& .caas-datagrid-red-row': {
      backgroundColor: '#F8D7DA',
      '&:hover': {
        backgroundColor: lighten('#F8D7DA', 0.7),
      },
      '&.Mui-selected': {
        backgroundColor: lighten('#F8D7DA', 0.5),
        '&:hover': {
          backgroundColor: lighten('#F8D7DA', 0.4),
        },
      },
    },
  }

  const customFilterColumnType: GridColTypeDef = {
    ...GRID_STRING_COL_DEF,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === 'contains'
    ),
  }

  const customFilterColumnTypeDate: GridColTypeDef = {
    ...GRID_STRING_COL_DEF,
    filterOperators: getGridDateOperators().filter(
      (operator) =>
        operator.value === 'onOrBefor' || operator.value === 'onOrAfter'
    ),
  }

  const buildColumns = (acronym: string): GridColDef[] => {
    const index = tenantConfig.tenants.findIndex(
      (tenant) => tenant.id === acronym
    )

    return [
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.claimId || 'Claim Id',
        field: 'claimId',
        type: 'string',
        width: 150,
        flex: 1,
        align: 'left',
        filterable: false,
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`ClaimId-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.claimId}
            </span>
          )
        },
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.claimNumber ||
          'Claim Number',
        field: 'claimNumber',
        type: 'string',
        width: 150,
        flex: 1,
        align: 'left',
        filterable: true,
        renderCell: (index: GridRenderCellParams) => {
          return (
            <Button
              variant="contained"
              size="small"
              sx={{
                borderRadius: '20px',
                padding: '0 20px',
                minWidth: '170px',
                minHeight: '32px',
                fontWeight: '600',
                textTransform: 'none',
                color: 'white',
                '&:hover': {
                  color: 'white',
                },
              }}
              href={`/claims/${index.row.claimNumber}?clinician=javalin`}
              target="_blank"
              rel="noreferrer"
              data-qa={`ClaimNumber-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.claimNumber}
            </Button>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.submitDate ||
          'Submit Date',
        field: 'submitDate',
        type: 'date',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`SubmitDate-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {formatDate(index.row.submitDate, true, 'dot')}
            </span>
          )
        },
        ...customFilterColumnTypeDate,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.renderingProvider ||
          'Rendering Provider',
        field: 'renderingProvider',
        type: 'string',
        width: 250,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`RenderingProvider-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.renderingProvider}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns.group || 'Group Name',
        field: 'groupName',
        type: 'string',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`GroupName-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.groupName}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName:
          tenantConfig.tenants[index]?.claimsGrid.columns
            .assignedUserFullName || 'Assigned User',
        field: 'assignedUserFullName',
        type: 'string',
        width: 200,
        filterable: true,
        flex: 1,
        align: 'left',
        renderCell: (index: GridRenderCellParams) => {
          return (
            <span
              data-qa={`AssignedUser-row${
                index.api.getRowIndexRelativeToVisibleRows(index.row.claimId) +
                1
              }`}
            >
              {index.row.assignedUserFullName}
            </span>
          )
        },
        ...customFilterColumnType,
      },
      {
        headerName: '',
        field: 'Info Icons',
        type: 'string',
        width: 200,
        filterable: false,
        flex: 1,
        align: 'left',
        sortable: false,
        disableColumnMenu: true,
        disableExport: true,
        hideSortIcons: true,
        renderCell: (index: GridRenderCellParams) => {
          var icon
          if (index.row.status === 'Sent To CAAS') {
            const hoursFromSubmitDate: number =
              Math.abs(Date.now() - Date.parse(index.row.submitDate)) / 36e5
            const isTwoDaysOrOlder: boolean = hoursFromSubmitDate >= 48
            const isDayOld: boolean =
              hoursFromSubmitDate >= 24 && hoursFromSubmitDate < 48

            if (isTwoDaysOrOlder) {
              icon = (
                <ReportIcon
                  data-qa={`two-days-or-older-icon-${index.row.claimId}`}
                  sx={{ marginRight: '50px', color: '#FF0000' }}
                />
              )
            } else if (isDayOld) {
              icon = (
                <WarningIcon
                  data-qa={`day-old-icon-${index.row.claimId}`}
                  sx={{ marginRight: '50px', color: '#FFA800' }}
                />
              )
            }
          }
          return (
            <div>
              {icon}
              {index.row.isAppeal ? (
                <BrightnessAutoIcon
                  data-qa={`is-appeal-icon-${index.row.claimId}`}
                  sx={{ color: '#573D82' }}
                />
              ) : null}
            </div>
          )
        },
        ...customFilterColumnType,
      },
    ]
  }

  function addDataQaAttributes(): void {
    // Find all elements with class "MuiDataGrid-columnHeaderTitle"
    const elements = document.querySelectorAll('.MuiDataGrid-columnHeaderTitle')

    // Loop through each element and modify its attributes
    elements.forEach((element) => {
      const innerText = (element as HTMLElement).innerText.replace(/\s+/g, '') // Remove all whitespace from inner text
      const dataQaValue = `columnHeaderTitle-${innerText}` // Create value for data-qa attribute
      element.setAttribute('data-qa', dataQaValue) // Set data-qa attribute
    })

    addDataQaAttributesToPagination()
    addDataQaAttributesToCaasHomepage()
  }

  function reloadData() {
    setStartDate(new Date(new Date().setDate(endDate.getDate() - 30)))
    setEndDate(new Date())
    setPage(0)
    setPageSize(10)

    setCurrentFilter(defaultFilter)
    setFilterClaimNumber('')
    setFilterDates([null, null])
    setFilterRenderingProvider('')
    setFilterGroupName('')
    setActiveFiltersCount(0)
    setFilterIsAdjudicated(false)
    setFilterIsAppeal(undefined)
    setFilterIsAssigned(true)
  }

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  })

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    if (newPaginationModel.pageSize !== pageSize) {
      onPageSizeChange(newPaginationModel.pageSize)
    }

    const newPage = newPaginationModel.page
    const lastPage = Math.ceil(rowCount / newPaginationModel.pageSize) - 1

    if (newPage > lastPage) {
      setPage(lastPage)
      setCurrentFilter((prev) => ({
        ...prev,
        pageNumber: lastPage + 1,
      }))
    } else {
      setPage(newPage)
      setCurrentFilter((prev) => ({
        ...prev,
        pageNumber: newPage + 1,
      }))
    }

    setPaginationModel(newPaginationModel)
  }

  const onPageSizeChange = (newPageSize: any) => {
    setPageSize(newPageSize)

    setCurrentFilter((prev) => ({
      ...prev,
      pageSize: newPageSize,
    }))
  }

  const getSearchString = (query: string) => {
    currentFilter.claimNumber = query
  }

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState
    )
  }, [rowCount, setRowCountState])

  useEffect(() => {
    if (isCallWaiting) return

    getClaims(currentFilter)
  }, [startDate, pageSize, page, datesDefault])

  useEffect(() => {
    addDataQaAttributes()
  }, [data])

  type filterParam = {
    field: string
    value: any
  }

  const onFilterChange = (filterModel: GridFilterModel) => {
    setPage(0)
    updateClaimsFilter(filterModel)

    const params: filterParam[] = []

    filterModel.items.map((item) => {
      item.value || item.value === false
        ? params.push({ field: item.field, value: item.value })
        : null
    })

    getFilteredData(params)
  }

  let dateFrom: string = new Date(
    new Date().setDate(startDate.getDate() - 364)
  ).toJSON()
  let dateTo: string = endDate.toJSON()
  let claimNumber: string,
    renderingProvider: string,
    groupName: string,
    isAppeal: boolean,
    isAssigned: boolean,
    isAdjudicated: boolean

  const isDateValid = (dateString: string): boolean => {
    const timestamp = Date.parse(dateString)
    return !isNaN(timestamp)
  }

  const getFilteredData = async (filterParams: filterParam[]) => {
    setIsCallWaiting(true)
    filterParams.map((param) => {
      if (param.field.includes('claimNumber')) {
        claimNumber = param.value
      }

      if (param.field.includes('submitDate')) {
        if (isDateValid(param.value[0])) {
          dateFrom = param.value[0]
        }

        if (isDateValid(param.value[1])) {
          dateTo = param.value[1]
        }
      }

      if (param.field.includes('renderingProvider')) {
        renderingProvider = param.value
      }

      if (param.field.includes('groupName')) {
        groupName = param.value
      }

      if (param.field.includes('isAppeal')) {
        isAppeal = param.value
      }

      if (param.field.includes('isAssigned')) {
        isAssigned = param.value
      }

      if (param.field.includes('isAdjudicated')) {
        isAdjudicated = param.value
      }
    })

    const finalFilter: CaasClaimsFilter = {
      tenantId: tenantId!,
      claimNumber: claimNumber,
      renderingProvider: renderingProvider,
      groupName: groupName,
      submitDateTo: dateTo ? dateTo : currentFilter.submitDateTo,
      submitDateFrom: dateFrom ? dateFrom : currentFilter.submitDateFrom,
      pageNumber: 1,
      pageSize: pageSize,
      sortOrder: 'ASC',
      sortField: 'SubmitDate',
      userName: '',
      isAppeal: isAppeal,
      isAssigned: isAssigned,
      isAdjudicated: isAdjudicated,
    }
    setIsLoading(true)
    const getCaasClaimsResponse: GetCaasClaimsResponse =
      await CaasApi.getClaims(finalFilter)

    if (
      !getCaasClaimsResponse.apiRequestStatusNumber ||
      String(getCaasClaimsResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getCaasClaimsResponse)
      setErrorSnackbarProps({
        errorMessage: getCaasClaimsResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getCaasClaimsResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
      setIsLoading(false)
      return
    }
    setIsLoading(false)
    setData(getCaasClaimsResponse.claimSearchResults)
    setUserTenantName(getCaasClaimsResponse.tenantName)
    setRowCount(getCaasClaimsResponse.claimCount)
    setCols(buildColumns(getCaasClaimsResponse.tenantAcronym))
    addDataQaAttributes()
    setIsCallWaiting(false)
  }

  const defaultCols = new Set<string>([
    'claimNumber',
    'status',
    'submitDate',
    'renderingProvider',
    'groupName',
    'assignedUserFullName',
    '',
  ])
  const [visibleColumns, setVisibleColumns] = useState(defaultCols)

  return (
    <div>
      {SelectTenantDropdown({displayAsText: false})}
      <div
        className="app"
        style={{
          padding: '20px',
          display: 'flex',
          marginTop: '30px',
        }}
      >
        <div className="banner">
          <h3
            data-qa={`header-${tenantName}-${rowCount}`}
            style={{ fontWeight: '700', fontSize: '24px', lineHeight: '40px' }}
          >
            Claims for {tenantName} ({rowCount})
          </h3>
          <div style={{ justifyContent: 'flex-end' }}>
            {hasData ? (
              <div>
                <span style={{ margin: '20px' }}>
                  You don&apos;t have assigned claims, do you want to see all
                  the claims?
                </span>
                <Button
                  variant="contained"
                  onClick={displayAllData}
                  data-qa="yes"
                >
                  Yes
                </Button>
              </div>
            ) : (
              <Button
                sx={{ fontWeight: '700' }}
                variant="contained"
                onClick={reloadData}
                data-qa="refresh-0"
              >
                <IoMdRefresh style={{ fontSize: '22px' }} />
                <span style={{ marginLeft: '10px' }}>Refresh</span>
              </Button>
            )}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            height: '68vh',
          }}
        >
          <DataGridPremium
            loading={isLoading}
            slots={{
              columnSortedAscendingIcon: () => (
                <CaasCustomSortIcon direction="asc" />
              ),
              columnSortedDescendingIcon: () => (
                <CaasCustomSortIcon direction="desc" />
              ),
              pagination: CustomPagination,
              toolbar: () => (
                <CaasCustomDataGridMenu
                  claimsFilter={currentFilter}
                  handleSearchInput={getSearchString}
                  activeFiltersCount={activeFiltersCount}
                />
              ),
              filterPanel: () => (
                <Paper sx={{ height: '555px' }}>
                  <CaasCustomFilterPanel
                    onChange={onFilterChange}
                    activeFiltersCount={activeFiltersCount}
                    setActiveFiltersCount={setActiveFiltersCount}
                    filterClaimNumber={filterClaimNumber}
                    setFilterClaimNumber={setFilterClaimNumber}
                    filterGroupName={filterGroupName}
                    setFilterGroupName={setFilterGroupName}
                    filterRenderingProvider={filterRenderingProvider}
                    setFilterRenderingProvider={setFilterRenderingProvider}
                    filterIsAdjudicated={filterIsAdjudicated}
                    setFilterIsAdjudicated={setFilterIsAdjudicated}
                    filterIsAppeal={filterIsAppeal}
                    setFilterIsAppeal={setFilterIsAppeal}
                    filterIsAssigned={filterIsAssigned}
                    setFilterIsAssigned={setFilterIsAssigned}
                    filterDates={filterDates}
                    setFilterDates={setFilterDates}
                    reloadData={reloadData}
                  />
                </Paper>
              ),
              columnsPanel: () => (
                <CaasCustomColumnsPanel
                  columns={cols}
                  visibleColumns={visibleColumns}
                  setVisibleColumns={setVisibleColumns}
                />
              ),
              openFilterButtonIcon: () => <RiFilterLine />,
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            onFilterModelChange={onFilterChange}
            filterMode="server"
            rows={data}
            rowCount={rowCountState}
            columns={cols}
            pageSizeOptions={[10, 25, 50, 100, 200]}
            pagination
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            onColumnVisibilityModelChange={() => {
              setTimeout(() => {
                addDataQaAttributes()
              }, 10)
            }}
            initialState={{
              columns: { columnVisibilityModel: { claimId: false } },
              sorting: {
                sortModel: [{ field: 'submitDate', sort: 'asc' }],
              },
            }}
            getRowId={(row) => row.claimId}
            sx={sx}
            getRowClassName={(params) => {
              if (params.row.status === 'Sent To CAAS') {
                const hoursFromSubmitDate: number =
                  Math.abs(Date.now() - Date.parse(params.row.submitDate)) /
                  36e5
                const isTwoDaysOrOlder: boolean = hoursFromSubmitDate >= 48
                const isDayOld: boolean =
                  hoursFromSubmitDate >= 24 && hoursFromSubmitDate < 48
                if (isTwoDaysOrOlder) {
                  return `caas-datagrid-red-row`
                } else if (isDayOld) {
                  return `caas-datagrid-amber-row`
                }
              }
              return ''
            }}
          />
          <ErrorSnackbar
            errorMessage={errorSnackbarProps.errorMessage}
            title={errorSnackbarProps.title}
            isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
            handleCloseSnackbar={handleErrorSnackbarClose}
          ></ErrorSnackbar>
        </div>
      </div>
    </div>
  )
}

export default CaasClaimsGrid
