import '../sass/base/_global.scss'
import { Box, Button, SelectChangeEvent, Tooltip } from '@mui/material'
import { useState, useEffect } from 'react'
import { DataGridPremium, GridColDef, GridRenderCellParams, GridSortModel, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid-premium'
import CustomSortIcon from './CustomSortIcon'
import CustomColumnsPanel from './CustomColumnsPanel'
import { IIOFile } from '../models/api/IIOFile'
import { useNavigate } from 'react-router-dom'
import { AdminApi } from '../api/AdminApi'
import { GetIOFilesResponse } from '../models/api/GetIOFilesResponse'
import theme from '../themes/muiTheme'
import ErrorSnackbar from './ErrorSnackbar'
import { CaasApi } from '../api/CaasApi'
import SelectTenantDropdown from './SelectTenantDropdown'
import { ITenant } from '../models/api/ITenant'
import { GetCaasTenantsResponse } from '../models/api/GetCaasTenantsResponse'
import InfoSnackbar from './InfoSnackbar'

const IOFilesDownload = () => {
  const navigate = useNavigate()
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const [infoSnackbarProps, setInfoSnackbarProps] = useState({
    infoMessage: 'No new information.',
    isOpenInfoSnackbar: false,
    title: 'Info title.',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [tenantIdSelected, setTenantIdSelected] = useState<string>(localStorage.getItem('caas_tenant') || '')
  const [tenantNameSelected, setTenantNameSelected] = useState<string>('')
  const [tenants, setTenants] = useState<ITenant>({})
  const [cols, setCols] = useState<GridColDef[]>([
    {
      headerName: 'Key',
      field: 'key',
      type: 'string',
      width: 150,
      flex: 1,
      align: 'left',
      filterable: false,
    },
    {
      headerName: 'File Name',
      field: 'fileName',
      type: 'string',
      width: 150,
      flex: 1,
      align: 'left',
      filterable: true,
      sortable: true,
      renderCell: (index: GridRenderCellParams) => {
        return (
          <Button
            variant="contained"
            size="small"
            sx={{
              borderRadius: '20px',
              padding: '0 20px',
              minWidth: '170px',
              minHeight: '32px',
              fontWeight: '600',
              textTransform: 'none',
              color: 'white',
              '&:hover': {
                color: 'white',
              },
            }}
            onClick={async () => {
              const blob: Blob = (await AdminApi.downloadIOFile(index.row.key)).blob
              var a = document.createElement('a');
              var url = window.URL.createObjectURL(blob);
              a.href = url;
              a.download = index.row.fileName;
              a.click();
              window.URL.revokeObjectURL(url);
            }}
          >
            {index.row.fileName}
          </Button>
        )
      }
    },
    {
      headerName: 'Tenant Acronym',
      field: 'tenantAcronym',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      flex: 1,
      align: 'left'
    },
    {
      headerName: 'File Type',
      field: 'fileType',
      type: 'string',
      width: 100,
      filterable: true,
      sortable: true,
      flex: 1,
      align: 'left'
    },
    {
      headerName: 'Last Modified Date',
      field: 'formattedLastModifiedDate',
      type: 'string',
      width: 200,
      filterable: true,
      sortable: true,
      flex: 1,
      align: 'left'
    },
    {
      headerName: 'Size',
      field: 'sizeString',
      type: 'string',
      width: 250,
      filterable: true,
      sortable: true,
      flex: 1,
      align: 'left'
    }
  ])
  const [filesGridData, setFilesGridData] = useState<IIOFile[]>([])
  const defaultCols = new Set<string>([
    'fileName',
    'fileType',
    'formattedLastModifiedDate',
    'size',
  ])
  const [visibleColumns, setVisibleColumns] = useState(defaultCols)

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }

  const handleInfoSnackbarClose = () => {
    setInfoSnackbarProps({
      ...infoSnackbarProps,
      isOpenInfoSnackbar: false,
    })
  }

  const formatDateToUS = (dateToFormat: Date) => {
    return `${("0" + (dateToFormat.getMonth() + 1)).slice(-2)}.${("0" + dateToFormat.getDate()).slice(-2)}.${dateToFormat.getFullYear()} ${dateToFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`;
  }


  useEffect(() => {
    loadDataForPage()
  }, [tenantIdSelected, tenantNameSelected])

  const loadDataForPage = async () => {
    setIsLoading(true)
    let tenantIdForApi: string
    if (!Object.keys(tenants).length || !tenantIdSelected!) {
      const getTenantsResponse: GetCaasTenantsResponse = await CaasApi.getTenants()

      if (
        !getTenantsResponse.apiRequestStatusNumber ||
        String(getTenantsResponse.apiRequestStatusNumber)[0] !== '2'
      ) {
        console.error(getTenantsResponse)
        setErrorSnackbarProps({
          errorMessage: getTenantsResponse.apiRequestStatusText,
          isOpenErrorSnackbar: true,
          title: `Request status: ${getTenantsResponse.apiRequestStatusNumber ?? 'unknown'
            }`,
        })
        return
      }
      const tenantsFromApi: ITenant = getTenantsResponse.tenants
      setTenants(tenantsFromApi)
      const tenantToSet: string = tenantIdSelected || Object.keys(tenantsFromApi)[0]
      setTenantIdSelected(tenantToSet)
      tenantIdForApi = tenantToSet
    }

    if (!tenantIdSelected && !tenantIdForApi!) {
      return
    }

    let getIOFilesResponse: GetIOFilesResponse = await AdminApi.getIOFiles(tenantIdSelected || tenantIdForApi!)

    if (
      !getIOFilesResponse.apiRequestStatusNumber ||
      String(getIOFilesResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      if (getIOFilesResponse.apiRequestStatusNumber === 401) {
        localStorage.setItem(
          'requestedUrl',
          window.location.pathname + window.location.search
        )
        navigate('/login', { replace: true })
      } else {
        console.error(getIOFilesResponse)
        setErrorSnackbarProps({
          errorMessage: getIOFilesResponse.apiRequestStatusText,
          isOpenErrorSnackbar: true,
          title: `Request status: ${getIOFilesResponse.apiRequestStatusNumber ?? 'unknown'}`,
        })
        setIsLoading(false)
      }
      return
    }

    setFilesGridData(getIOFilesResponse.ioFiles.map(item => {
      return {
        ...item,
        tenantAcronym: item.key.split('/')[0],
        formattedLastModifiedDate: formatDateToUS(item.lastModifiedDate),
        sizeString: `${item.size} bytes`
      }
    }))
    setTenantNameSelected(tenants[tenantIdSelected])
    setIsLoading(false)
  }

  const handleTenantSelectChange = (event: SelectChangeEvent) => {
    setTenantIdSelected(event.target.value)
    localStorage.setItem('caas_tenant', event.target.value)
  }

  const customToolbar = () => {
    const fileName: string = `List of files for '${tenantNameSelected}' tenant`
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            fileName: fileName,
          }}
          printOptions={{
            fileName: fileName,
          }}
          excelOptions={{
            fileName: fileName,
          }}
        />
        <Box sx={{ flexGrow: 1 }} />
      </GridToolbarContainer>
    );
  }

  const sx = {
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: '700',
      color: `${theme.palette.primary.main}`,
    },
    '& .MuiDataGrid-cell': {
      fontWeight: '700',
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-toolbarContainer': {
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      '& button': {
        color: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.primary.main}`,
      },
    },
    '& .MuiTablePagination-caption[id]': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-input': {
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    '& .MuiTablePagination-selectLabel': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
    '& .MuiTablePagination-displayedRows': {
      marginTop: 'initial !important',
      marginBottom: 'initial !important',
    },
  }

  return (
    <div>
      <SelectTenantDropdown
        displayAsText={false}
        handleTenantSelectChange={handleTenantSelectChange}
        tenantsPassed={tenants}
        tenantIdSelected={tenantIdSelected} />
      <div
        className="app"
        style={{
          padding: '20px',
          display: 'flex',
          marginTop: '30px',
        }}>
        <div
          style={{
            float: 'left',
            display: 'block'
          }}>
          <h1 style={{
            clear: 'left',
            float: 'left',
            width: 'auto'
          }}>Input and Output files for {tenantNameSelected}</h1>
          <Tooltip title={`Clicking this button would generate a new report that is current for '${tenantNameSelected}' tenant at ${new Date().toLocaleString()}`}>
            <Button
              variant="contained"
              size="small"
              disabled={isLoading}
              sx={{
                marginBottom: '20px',
                minWidth: '170px',
                minHeight: '32px',
                maxWidth: '300px',
                fontWeight: '600',
                textTransform: 'none',
                color: 'white',
                '&:hover': {
                  color: 'white',
                },
                clear: 'left',
                float: 'left',
                width: 'auto'
              }}
              onClick={async () => {
                setIsLoading(true)
                AdminApi.generateNewReports(tenantIdSelected).then(() => {
                  setInfoSnackbarProps({
                    infoMessage: 'Successfully sent a request to generate new files',
                    isOpenInfoSnackbar: true,
                    title: `New files generated`,
                  })
                })
                setTimeout(() => setIsLoading(false), 500)
              }}
            >
              <span style={{ margin: '0px 10px 0px 10px' }}>Generate Latest Output File</span>
            </Button>
          </Tooltip>

        </div>

        <DataGridPremium
          loading={isLoading}
          slots={{
            columnSortedAscendingIcon: () => <CustomSortIcon direction="asc" />,
            columnSortedDescendingIcon: () => (
              <CustomSortIcon direction="desc" />
            ),
            toolbar: customToolbar,
            columnsPanel: () => (
              <CustomColumnsPanel
                columns={cols}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
              />
            ),
          }}
          filterMode="client"
          rows={filesGridData}
          columns={cols}
          pageSizeOptions={[10, 25, 50, 100, 200]}
          pagination
          paginationMode="client"
          initialState={{
            columns: { columnVisibilityModel: { key: false } },
            pagination: { paginationModel: { pageSize: 25 } },
            sorting: {
              sortModel: [{ field: 'lastModifiedDate', sort: 'desc' }],
            }
          }}
          getRowId={(row) => row.key}
          sx={sx}
        />
        <ErrorSnackbar
          errorMessage={errorSnackbarProps.errorMessage}
          title={errorSnackbarProps.title}
          isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
          handleCloseSnackbar={handleErrorSnackbarClose}
        ></ErrorSnackbar>
        <InfoSnackbar
          infoMessage={infoSnackbarProps.infoMessage}
          title={infoSnackbarProps.title}
          isOpenInfoSnackbar={infoSnackbarProps.isOpenInfoSnackbar}
          handleCloseSnackbar={handleInfoSnackbarClose}
        ></InfoSnackbar>
      </div>
    </div>
  )
}

export default IOFilesDownload
