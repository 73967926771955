import { AxiosError } from 'axios'
import api from './config/axiosConfig'
import { IApiResponse } from '../models/api/IApiResponse'
import { AxiosErrorResponse } from '../models/api/AxiosErrorResponse'
import { GetIOFilesResponse } from '../models/api/GetIOFilesResponse'
import { IIOFile } from '../models/api/IIOFile'
import { DownloadIOFileResponse } from '../models/api/DownloadIOFileResponse'

export interface IAdminApi {
  getIOFiles: (tenantId: string) => Promise<GetIOFilesResponse>
  downloadIOFile: (key: string) => Promise<DownloadIOFileResponse>
  generateNewReports: (tenantId: string) => Promise<void>
}

export const AdminApi: IAdminApi = {
  getIOFiles: async (tenantId: string): Promise<GetIOFilesResponse> => {
    try {
      const response = await api.get(`admin/io_files`, {
        params: {
          TenantId: tenantId
        }
      })
      return {
        ioFiles: response.data.map((file: IIOFile, index: number) => { return { ...file, lastModifiedDate: new Date(file.lastModifiedDate) } }),
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ... new GetIOFilesResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },
  downloadIOFile: async (key: string): Promise<DownloadIOFileResponse> => {
    try {
      const response = await api.get(`admin/io_files/download/${encodeURIComponent(key)}`, { responseType: 'blob' })
      return {
        blob: response.data,
        apiRequestStatusNumber: response.status,
        apiRequestStatusText: response.statusText,
      }
    } catch (error: any) {
      console.log(error)
      return {
        ... new DownloadIOFileResponse(),
        ...getIApiResponseFromError(
          error as AxiosError<AxiosErrorResponse, any>
        ),
      }
    }
  },
  generateNewReports: async (tenantId: string): Promise<void> => {
    try {
      await api.get(`admin/io_files/generate_output_files`, {
        params: {
          TenantId: tenantId
        }
      })
    } catch (error: any) {
      console.log(error)
    }
  }
}

function getIApiResponseFromError(
  error: AxiosError<AxiosErrorResponse, any>
): IApiResponse {
  if (error.response) {
    const finalErrorMessage =
      error.response.data.message ??
      error.message ??
      'Unknown Error Encountered'
    return {
      apiRequestStatusText: finalErrorMessage,
      apiRequestStatusNumber: error.response.status,
    }
  }
  if (error.message) {
    // hack to solve bug 130239 since no better solution has been found yet
    if (error.message === 'Network Error')
      return {
        apiRequestStatusText:
          'Cannot complete the request. Try logging out and back in.',
        apiRequestStatusNumber: 401,
      }
    else {
      return {
        apiRequestStatusText: error.message,
      }
    }
  }
  return {
    apiRequestStatusText: 'Unknown Error Encountered',
  }
}
