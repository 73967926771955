import '../sass/base/_global.scss'
import '../sass/components/details/_details.scss'
import '../sass/components/details/_details-comments.scss'
import { styled } from '@mui/material/styles'
import ClaimDetailsDataLine from './ClaimDetails/ClaimDetailsDataLine'
import ClaimDetailsReviewInfoCard from './ClaimDetails/ClaimDetailsReviewInfoCard'
import ClaimDetailsImageGallery from './ClaimDetails/ClaimDetailsImageGallery'
import { ClaimDetailsClaimLevelNarrative } from './ClaimDetails/ClaimDetailsClaimLevelNarrative'
import ClaimDetailsCommentsTable from './ClaimDetails/ClaimDetailsCommentsTable'
import ClaimsDetailsHistoryTable from './ClaimDetails/ClaimDetailsHistoryTable'
import {
  useEffect,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { AuthContext } from '../auth/AuthProvider'
import { ClaimsApi } from '../api/ClaimsApi'
import ErrorSnackbar from './ErrorSnackbar'
import { Box, Tabs, Tab, LinearProgress } from '@mui/material'
import React from 'react'
import SelectTenantDropdown from './SelectTenantDropdown'

export const CommentsContext = createContext<any>({})

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
})

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  borderRadius: '5px',
  minWidth: '10vw',
  margin: '6px',
  color: 'black',
  '&:hover': {
    color: 'black',
    backgroundColor: '#80c275',
  },
  '&.Mui-selected': {
    color: 'white',
    fontWeight: 'bold',
    backgroundColor: '#2db035',
  },
}))

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ overflow: 'auto', my: '10px' }}>
          {children}
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ClaimDetails = () => {
  const authContext = useContext(AuthContext)
  const { search } = useLocation();
  const searchParam =  React.useMemo(() => new URLSearchParams(search), [search]);
  let userTenantId : string | null;
  if(searchParam.get("clinician") == "javalin")
    {
      userTenantId = localStorage.getItem('caas_tenant')
    }
  else
    {
      userTenantId = authContext.getTenantId(authContext.cognitoAuth)
    }

  const [reviewInfoList, setReviewInfoList] = useState<any>(null)
  const [userCommentsList, setUserCommentsList] = useState<any>(null)
  const [claimDetailsData, setDetailsData] = useState<any>(null)
  const [claimDetailsInfo, setClaimDetailsInfo] = useState<any>(null)
  const { claimNumber } = useParams()
  const [narrative, setNarrative] = useState<string>()
  const [concatenatedNarrative, setConcatenatedNarrative] = useState<string>()
  const [claimLines, setClaimLines] = useState<any>()
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const updateState = useCallback(async () => {
    const getClaimResponse = await ClaimsApi.getClaim(
      userTenantId!,
      claimNumber!
    )
    if (
      !getClaimResponse.apiRequestStatusNumber ||
      String(getClaimResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getClaimResponse)
      setErrorSnackbarProps({
        errorMessage: getClaimResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getClaimResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
      return
    }

    const getClaimReviewResponse = await ClaimsApi.getClaimReview(
      userTenantId!,
      claimNumber!
    )

    if (
      !getClaimReviewResponse.apiRequestStatusNumber ||
      String(getClaimReviewResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getClaimReviewResponse)
      setErrorSnackbarProps({
        errorMessage: getClaimReviewResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getClaimReviewResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
    }

    const getClaimCommentsResponse = await ClaimsApi.getClaimComments(
      userTenantId!,
      claimNumber!
    )

    if (
      !getClaimCommentsResponse.apiRequestStatusNumber ||
      String(getClaimCommentsResponse.apiRequestStatusNumber)[0] !== '2'
    ) {
      console.error(getClaimCommentsResponse)
      setErrorSnackbarProps({
        errorMessage: getClaimCommentsResponse.apiRequestStatusText,
        isOpenErrorSnackbar: true,
        title: `Request status: ${
          getClaimCommentsResponse.apiRequestStatusNumber ?? 'unknown'
        }`,
      })
    }

    if (getClaimResponse.claimlines) {
      setClaimLines(getClaimResponse.claimlines)
    }

    if (getClaimReviewResponse.claimReviews.length > 0) {
      setReviewInfoList(getClaimReviewResponse.claimReviews)
    }

    if (getClaimCommentsResponse.claimComments.length > 0) {
      setUserCommentsList(getClaimCommentsResponse.claimComments)
    }

    if (getClaimResponse.narrative) {
      setNarrative(getClaimResponse.narrative)
    }

    if (getClaimResponse.concatenatedNarrative) {
      setConcatenatedNarrative(getClaimResponse.concatenatedNarrative)
    }

    setClaimDetailsInfo({ userTenantId, claimNumber: claimNumber })
    setDetailsData(getClaimResponse)
  }, [userTenantId])

  useEffect(() => {
    if (userTenantId) {
      updateState().catch((error) => {
        console.error(error)
      })
    }
  }, [userTenantId])

  const handleErrorSnackbarClose = () => {
    setErrorSnackbarProps({
      ...errorSnackbarProps,
      isOpenErrorSnackbar: false,
    })
  }
  const updateComments = (comments: any) => {
    setUserCommentsList(comments)
  }

  const getSelectTenantDropdown = () =>
  {
    if(searchParam.get("clinician") === "javalin")
      return SelectTenantDropdown({displayAsText: true})
  }

  return (
    <div>
    {getSelectTenantDropdown()}
      <CommentsContext.Provider value={{ updateComments, userCommentsList }}>
        {claimDetailsData === null ? (
          <LinearProgress color="primary" />
        ) : (
          <>
            <ClaimDetailsDataLine claimDetailsData={claimDetailsData} />
            <div className="container-flex">
              <div className="column-1">
                <div className="row-1">
                  {typeof concatenatedNarrative !== 'undefined' ? (
                    <div className="review-narrative">
                      <h3
                        data-qa={'h3-narrative'}
                        className="review-narrative-header"
                      >
                        Narrative
                      </h3>
                      <div id="concatenatedNarrative" className="review-narrative-pane">
                        <ClaimDetailsClaimLevelNarrative
                          concatenatedNarrative={concatenatedNarrative}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="row-2">
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Tabs: Review | Comments | History"
                  >
                    <StyledTab
                      label="Review"
                      {...a11yProps(0)}
                      data-qa="tab-review"
                    />
                    <StyledTab
                      label="Comments"
                      {...a11yProps(1)}
                      data-qa="tab-comments"
                    />
                    <StyledTab
                      label="History"
                      {...a11yProps(2)}
                      data-qa="tab-history"
                    />
                  </StyledTabs>
                  <TabPanel value={value} index={0}>
                    {reviewInfoList ? (
                      <ClaimDetailsReviewInfoCard
                        claimDetailsData={claimDetailsData}
                        reviewInfoList={reviewInfoList}
                        claimLines={claimLines}
                      />
                    ) : (
                      <div></div>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {userCommentsList ? (
                      <ClaimDetailsCommentsTable
                        tenantId={authContext.getTenantId(
                          authContext.cognitoAuth
                        )}
                        userName={authContext.getUserDisplayName(
                          authContext.cognitoAuth
                        )}
                        claim={claimDetailsData}
                        comments={userCommentsList}
                      />
                    ) : (
                      <div>No records found.</div>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <ClaimsDetailsHistoryTable />
                  </TabPanel>
                </div>
              </div>
              <div className="column-2">
                <div className="images-section">
                  <div className="image-photo">
                    <div>
                      {claimDetailsInfo ? (
                          <ClaimDetailsImageGallery
                            claimDetailsInfo={claimDetailsInfo}/>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </CommentsContext.Provider>
      <ErrorSnackbar
        errorMessage={errorSnackbarProps.errorMessage}
        title={errorSnackbarProps.title}
        isOpenErrorSnackbar={errorSnackbarProps.isOpenErrorSnackbar}
        handleCloseSnackbar={handleErrorSnackbarClose}
      ></ErrorSnackbar>
    </div>
  )
}

export default ClaimDetails
