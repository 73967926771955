import { Alert, AlertTitle, Portal, Snackbar } from '@mui/material'

export interface InfoSnackbarProps {
  infoMessage: string
  title: string
  isOpenInfoSnackbar: boolean
  handleCloseSnackbar?: any
}

const InfoSnackbar = ({ infoMessage: message, title, isOpenInfoSnackbar: isOpenInfoSnackbar, handleCloseSnackbar: handleCloseInfoSnackbar }: InfoSnackbarProps) => {


  return (
    <div>
      <Portal>
        <Snackbar
          open={isOpenInfoSnackbar}
          autoHideDuration={10000}
          onClose={handleCloseInfoSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert onClose={handleCloseInfoSnackbar} severity="success">
            <AlertTitle>{title}</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      </Portal>
    </div>
  )
}

export default InfoSnackbar
