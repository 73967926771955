import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ITenant } from '../models/api/ITenant'
import { GetCaasTenantsResponse } from '../models/api/GetCaasTenantsResponse'
import { CaasApi } from '../api/CaasApi'

interface SelectTenantDropdownProps {
  displayAsText: boolean
  handleTenantSelectChange?: (event: SelectChangeEvent) => void
  tenantsPassed?: ITenant
  tenantIdSelected?: string
}

const handleTenantSelectChangeDefault = (event: SelectChangeEvent) => {
  localStorage.setItem('caas_tenant', event.target.value)
  window.location.reload()
}


const SelectTenantDropdown = (props: SelectTenantDropdownProps) => {
  const navigate = useNavigate()

  const [tenantId, setTenantId] = useState<string>(props.tenantIdSelected || localStorage.getItem('caas_tenant') || '')
  const [tenants, setTenants] = useState<ITenant>(props.tenantsPassed || {})
  const [tenantName, setTenantName] = useState(tenants[tenantId] || '')
  const [errorSnackbarProps, setErrorSnackbarProps] = useState({
    errorMessage: 'Unkown Error Occurred.',
    isOpenErrorSnackbar: false,
    title: 'Status unknown.',
  })

  const getTenants = async () => {
    let tenantsKeyValues: ITenant

    if (props.tenantsPassed) {
      tenantsKeyValues = props.tenantsPassed
    }
    else {
      let getTenantsResponse: GetCaasTenantsResponse = await CaasApi.getTenants()
      if (
        !getTenantsResponse.apiRequestStatusNumber ||
        String(getTenantsResponse.apiRequestStatusNumber)[0] !== '2'
      ) {
        console.error(getTenantsResponse)
        setErrorSnackbarProps({
          errorMessage: getTenantsResponse.apiRequestStatusText,
          isOpenErrorSnackbar: true,
          title: `Request status: ${getTenantsResponse.apiRequestStatusNumber ?? 'unknown'
            }`,
        })
        return
      }
      tenantsKeyValues = getTenantsResponse.tenants
    }

    setTenants(tenantsKeyValues)
    Object.entries(tenantsKeyValues).map((array) => {
      if (array[0] === tenantId!) {
        setTenantId(tenantId)
        setTenantName(array[1])
      }
    })
  }

  useEffect(() => {
    getTenants()
  }, [props.tenantsPassed])


  const getTenantText = () => {
    if (props.displayAsText) {
      return 'Tenant ' + tenantName
    } else return 'Select Tenant'
  }

  return (
    <div
      // hidden={tenantId.length > 0 && Object.keys(tenants).length > 0}
      style={{
        backgroundColor: '#D5CEE0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '45px',
        paddingLeft: props.displayAsText ? '160px' : '0px',
        paddingRight: props.displayAsText ? '0px' : '32px'
      }}
    >
      <div
        style={{
          margin: 'auto',
        }}
      >
        <div
          style={{
            marginTop: '11px',
            display: 'inline-block',
          }}
        >
          <span
            style={{
              fontFamily:
                'Gotham,"Segoe UI Bold",Roboto,Geneva,Verdana,sans-serif',
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#262626',
            }}
            data-qa="tenant-select-label"
          >
            {getTenantText()}
          </span>
        </div>

        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <Select
            labelId="tenant-select-dropdown"
            id="tenant-select-dropdown"
            classes={{ nativeInput: 'caas-tenant-select-input' }}
            label="Tenant"
            value={props.tenantIdSelected ||  tenantId!}
            onChange={props.handleTenantSelectChange || handleTenantSelectChangeDefault}
            hidden={props.displayAsText}
            sx={{
              fontFamily: 'Gotham,"Segoe UI",Roboto,Geneva,Verdana,sans-serif',
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: 'normal',
              color: '#262626',
              fontStyle: 'normal',
            }}
          >
            {Object.entries(tenants!).map((array) => {
              return (
                <MenuItem key={array[0]} value={array[0]}>
                  <em>{array[1]}</em>
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

export default SelectTenantDropdown
